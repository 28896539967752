
export const skills = [
    "HTML5",
    "CSS3",
    "JavaScript (ES6)",
    "TypeScript",
    "C#",
    "Unity",
    "Material UI",
    "PostgreSQL",
    "React.js",
    "Node.js",
    "Express",
    "Redux",
    "Git",
    "GitHub",
  ];
  